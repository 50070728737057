export const config = {
  RPC_URL: process.env.REACT_APP_RPC_URL,
  REST_URL: process.env.REACT_APP_REST_URL,
  EXPLORER_URL: "https://sixscan.io",
  STAKING_URL: "https://sixscan.io",
  NETWORK_NAME: process.env.REACT_APP_CHAIN_NAME,
  NETWORK_TYPE: process.env.REACT_APP_NETWORK_TYPE,
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
  CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME,
  COIN_DENOM: "SIX",
  COIN_MINIMAL_DENOM: "usix",
  COIN_DECIMALS: 6,
  PREFIX: "6x",
  COIN_TYPE: 118,
  COINGECKO_ID: "six-network",
  DEFAULT_GAS: 300000,
  GAS_PRICE_STEP_LOW: 1.25,
  GAS_PRICE_STEP_AVERAGE: 1.45,
  GAS_PRICE_STEP_HIGH: 1.65,
  FEATURES: ["ibc-transfer", "cosmwasm", "ibc-go"],
  FEES: {
    upload: {
      amount: [
        {
          amount: "6250000",
          denom: "usix"
        }
      ],
      gas: "5000000"
    },
    init: {
      amount: [
        {
          amount: "625000",
          denom: "usix"
        }
      ],
      gas: "500000"
    },
    exec: {
      amount: [
        {
          amount: "5000000",
          denom: "usix"
        }
      ],
      gas: "4000000"
    }
  },
  CONTRACT_DATA: {
    controller: {
      codeId: 99,
      contract_addr: process.env.REACT_APP_CONTROLLER_ADDRESS
    },
    registrar: {
      codeId: 100,
      contract_addr: process.env.REACT_APP_REGISTRAT_ADDRESS
    },
    registry: {
      codeId: 101,
      contract_addr: process.env.REACT_APP_REGISTRY_ADDRESS
    },
    resolver: {
      codeId: 102,
      contract_addr: process.env.REACT_APP_RESOLVER_ADDRESS
    }
  }
};
