import React from 'react';
import { Helmet } from 'react-helmet-async';

const CustomHelmet = () => {
  return (
    <Helmet>
      <title>SIX Protocol - Blockchain Infrastructure For Business</title>
      <meta
        name="keywords"
        content="Blockchain built to bring business to the Web 3.0 era"
      ></meta>
      <meta
        name="description"
        content="SIX Protocol is the blockchain layer built with toolkits conveniently made for business adoption of the blockchain and entering the Web 3.0 era."
      ></meta>
    </Helmet>
  );
};

export default CustomHelmet;
