import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 970,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: `'Montserrat', 'sans-serif'`,
    color: "#444",
    fontWeightRegular: "400",

    h1: {
      fontWeight: "800",
    },
    h2: {
      fontWeight: "800",
    },
    h3: {
      fontWeight: "800",
    },
    h4: {
      fontWeight: "800",
    },
    h5: {
      fontWeight: "800",
    },
    h6: {
      fontWeight: "800",
    },
  },
  palette: {
    primary: {
      main: "#269adc",
    },
    secondary: {
      main: "#2ab2de",
    },
    text: {
      primary: "#444",
      secondary: "#A4ABB5",
    },
    success: {
      main: "#24b272",
    },
    warning: {
      main: "#FFC107",
    },
    error: {
      main: "#e24640",
    },
    gray: "#f9f9f9",
    white: "#ffffff",
    border: "rgba(102, 119, 136, 0.1)",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: "1.7",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          background: "#F1F3F5 !important",

          "&&:before": {
            display: "none",
          },
          "&&:after": {
            display: "none",
          },
        },
        input: {
          padding: "10px 12px",
          "&::placeholder": {
            color: "#A4ABB5",
            opacity: "1",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: "0 !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: "480px",
          fontWeight: "700",
          textTransform: "initial",
          borderRadius: "2rem",
          "&& i": { fontSize: "inherit" },

          "> img": { height: "100%", display: "block" },
        },

        sizeSmall: {
          height: "36px",
        },
        sizeLarge: {
          height: "48px",
          fontSize: "1rem !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "1rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: {
          height: "36px",
          fieldset: {
            borderRadius: "36px",
          },
          svg: {
            color: "#269adc",
          },
        },
        input: {
          boxSizing: "border-box",
          height: "100% !important",
          lineHeight: "20px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: "right",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "0.5rem !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          borderColor: "rgba(102, 119, 136, 0.1)",
        },
        head: {
          paddingTop: "8px",
          paddingBottom: "8px",
          opacity: "0.5",
          fontSize: "0.75rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child .MuiTableCell-body": { border: "none" },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
        },
      },
    },
  },
});

theme.shadows[3] = "0 0.3125rem 0.9375rem 0 rgba(0,0,0,0.1)";

theme = responsiveFontSizes(theme);

export default theme;
