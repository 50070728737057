import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./App";
import LoadingPage from "./components/LoadingPage";
import Providers from "./Providers";
import reportWebVitals from "./reportWebVitals";
import { RefreshProvider } from "./contexts/RefreshContext";
import "./stylesheets/global.scss";
import "./index.css";
import ReactGA from "react-ga";
// import NFTgen2Page from "./pages/NFTgen2Page";

ReactGA.initialize("UA-228567463-1");

const HomePage = lazy(() => import("./pages/HomePage"));
const NewBecomeANodesPage = lazy(() => import("./pages/NewBecomeANodesPage"));
const SummaryPage = lazy(() => import("./pages/SummaryPage"));

const SalePage = lazy(() => import("./pages/SalePage"));
const NFTTicketingPage = lazy(() => import("./pages/NFTTicketingPage"));
const NFTMembershipPage = lazy(() => import("./pages/NFTMembershipPage"));
const RedeemableNFTPage = lazy(() => import("./pages/RedeemableNFTPage"));
const SaleThanksPage = lazy(() => import("./pages/SaleThanksPage"));

// const SixValidatorNodesPage = lazy(() =>
//   import("./pages/SixValidatorNodesPage")
// );
// const PartnershipProgramPage = lazy(() =>
//   import("./pages/PartnershipProgramPage")
// );
// const SixTokenPage = lazy(() => import("./pages/SixTokenPage"));
const PDPAPolicy = lazy(() => import("./pages/PDPAPolicy"));

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <RefreshProvider>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<HomePage />} />
                <Route
                  path="/become-a-node"
                  element={<NewBecomeANodesPage />}
                />
                <Route path="/learn-more" element={<SalePage />} />
                <Route
                  path="/learn-more/nft-ticketing"
                  element={<NFTTicketingPage />}
                />
                <Route
                  path="/learn-more/nft-membership"
                  element={<NFTMembershipPage />}
                />
                <Route
                  path="/learn-more/redeemable-nft"
                  element={<RedeemableNFTPage />}
                />
                <Route path="/learn-more/thanks" element={<SaleThanksPage />} />

                {/* <Route
                path="/six-validator-nodes"
                element={<SixValidatorNodesPage />}
              />
              <Route
                path="/partnership-program"
                element={<PartnershipProgramPage />}
              />
              <Route path="/six-token" element={<SixTokenPage />} />
              

              <Route path="/nftgen2" element={<NFTgen2Page />} /> */}

                <Route path="/privacy-policy" element={<PDPAPolicy />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>

              <Route path="/summary" element={<SummaryPage />} />
            </Routes>
          </RefreshProvider>
        </Suspense>
      </BrowserRouter>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
