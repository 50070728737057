import React, { useEffect, useState } from "react";
import { Container, Grow, IconButton } from "@mui/material";
import back from "../images/NewUI/scroll_top_btn.png";

const BackToTop = () => {
  const [isShowBackToTop, setIsShowBackToTop] = useState(false);

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    if (position > 960) {
      setIsShowBackToTop(true);
    } else if (position < 960) {
      setIsShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleVisibleButton);
    };
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "sticky",
        bottom: "24px",
        zIndex: "999",
        pointerEvents: "none",
        display: "flex",
        justifyContent: "flex-end",
        mt: "-200px",
      }}
    >
      <Grow in={isShowBackToTop}>
        <IconButton
          sx={{
            width: "64px",
            p: 0,
            pointerEvents: "initial",
          }}
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <img src={back} alt="" />
        </IconButton>
      </Grow>
    </Container>
  );
};

export default BackToTop;
