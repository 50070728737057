import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  // getTransactions,
  getTransactionsDelegate,
  getTransactionsUndelegate,
  getTransactionsClaim
} from "../../constants/url";

const initialState = {
  allTransactions: {}
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setAllTransactions: (state, action) => {
      const allTransactions = action.payload;
      state.allTransactions = allTransactions;
    }
  }
});

export const { setAllTransactions } = transactionsSlice.actions;

export default transactionsSlice.reducer;

const getTransactionByIndex = async ({ sender, pages = 1 }) => {
  let allTransactionIndex = {};
  // const url = getTransactions(sender, pages);
  const url1 = getTransactionsDelegate(sender, pages);
  const url2 = getTransactionsUndelegate(sender, pages);
  const url3 = getTransactionsClaim(sender, pages);
  try {
    let dataArray = {};
    let transactionDelegate = [{}];
    let transactionUndelegate = [{}];
    let transactionsClaim = [{}];

    const fetchURL = (url) => axios.get(url);
    const promiseArray = [url1, url2, url3].map(fetchURL);

    await Promise.all(promiseArray)
      .then((res) => {
        if (Number(res[0].data.total_count) > 0) {
          transactionDelegate = res[0].data.txs;
        }

        if (Number(res[1].data.total_count) > 0) {
          transactionUndelegate = res[1].data.txs;
        }

        if (Number(res[2].data.total_count) > 0) {
          transactionsClaim = res[2].data.txs;
        }
      })
      .catch(() => {
        transactionDelegate = [{}];
        transactionUndelegate = [{}];
        transactionsClaim = [{}];
      });

    const mergeData = [...transactionDelegate, ...transactionUndelegate, ...transactionsClaim];
    const arr = mergeData.filter(({ txhash }) => txhash);
    const sortLastTime = arr.sort((a, b) => b.height - a.height).slice(0, 50);

    const mapData = {
      count: String(sortLastTime.length),
      limit: "50",
      page_number: "1",
      page_total: "1",
      total_count: String(sortLastTime.length),
      txs: sortLastTime
    };
    dataArray = mapData;
    allTransactionIndex = dataArray;
  } catch (error) {
    allTransactionIndex = {};
  }
  return [allTransactionIndex];
};

export const fetchTransactions =
  (sender, pages = 1) =>
  async (dispatch) => {
    const fetchPromise = [];
    fetchPromise.push(
      getTransactionByIndex({
        sender,
        pages
      })
    );
    const [[allTransactionIndex]] = await Promise.all(fetchPromise);
    dispatch(setAllTransactions({ allTransactionIndex }));
  };
