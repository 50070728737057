import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getDuplicateName, getName } from "../../constants/url";
// import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
// import { IRegistrar } from "../../contracts/IRegistrar";
// import { config } from "../../config";

// const chainId = config.CHAIN_ID;

const initialState = {
  nameService: {},
  duplicateName: {}
};

const nameServiceSlice = createSlice({
  name: "nameService",
  initialState,
  reducers: {
    setNameService: (state, action) => {
      const nameService = action.payload;
      state.nameService = nameService;
    },
    setDuplicateName: (state, action) => {
      const duplicateName = action.payload;
      state.duplicateName = duplicateName;
    }
  }
});

export const { setNameService, setDuplicateName } = nameServiceSlice.actions;

export default nameServiceSlice.reducer;

export const setName = (address) => (dispatch) => {
  const url = getName();

  axios
    .get(url, {
      params: {
        addr: address
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(setNameService(response.data.data));
      }
    })
    .catch((err) => {
      dispatch(setNameService({}));
    });
};

export const setDuplicate = (name) => (dispatch) => {
  const url = getDuplicateName();

  axios
    .get(url, {
      params: {
        name: name
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(setDuplicateName(response.data.data));
      }
    })
    .catch((err) => {
      dispatch(setDuplicateName({}));
    });
};

const getNameByIndex = async ({ name }) => {
  let allNameIndex = {};
  // try {
  //   (await window.keplr) && window.keplr.enable(chainId);
  //   const offlineSigner =
  //     window.getOfflineSignerOnlyAmino &&
  //     window.getOfflineSignerOnlyAmino(chainId);
  //   const client = await SigningCosmWasmClient.connectWithSigner(
  //     RPC_URL,
  //     offlineSigner
  //   );
  //   const registrar = IRegistrar(client, config.FEES);
  //   const SNS_REGISTRAR = registrar.use(
  //     config.CONTRACT_DATA.registrar.contract_addr
  //   );
  //   const token = await SNS_REGISTRAR.tokens(name);
  //   const { tokens } = token;
  //   if (tokens[0] !== undefined) {
  //     const nftInfo = await SNS_REGISTRAR.nftInfo(tokens[0]);
  //     const { token_uri, extension } = nftInfo;
  //     const { name } = extension;
  //     allNameIndex = { name: name, token_uri: `${token_uri}` };
  //   } else {
  //     allNameIndex = {};
  //   }
  // } catch (error) {
  //   allNameIndex = {};
  // }
  // let allNameIndex = {};
  const url = getName();
  try {
    let dataArray = {};

    await axios
      .get(url, {
        params: {
          addr: name
        }
      })
      .then(async (response) => {
        if (response.data.status) {
          dataArray = response.data.data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // allNameIndex = dataArray;
    allNameIndex = dataArray
  } catch (error) {
    allNameIndex = {};
  }

  return [allNameIndex];
};

export const fetchName = (name) => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getNameByIndex({ name }));
  const [[allNameIndex]] = await Promise.all(fetchPromise);
  dispatch(setNameService(allNameIndex));
};
