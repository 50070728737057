import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { operatorAddressToAccount, formatTokenAmount } from "../../helper";
import {
  urlFetchBalance,
  getValidatorURL,
  getStakingDelegatorDelegation,
  getMintingInflation,
  urlFetchRewards,
  getQuotes,
  urlFetchUnbonding,
  urlFetchTotalSupply,
  urlFetchStakedToken,
  urlFetchCommunityTax,
  urlFetchCommissionRate,
} from "../../constants/url";
import { config } from "../../config";

const initialState = {
  status: "idle",
  entities: {},
  accounts: {},
  balance: 0,
  validator: {},
  delegation: {},
  inflation: 0,
  delegator: {},
  stakingRewards: {
    rewards: {
      validator_address: "",
      reward: [
        {
          denom: "",
          amount: "",
        },
      ],
    },
    total: [
      {
        denom: "",
        amount: "",
      },
    ],
  },
  quotes: {},
  unbonding: {},
  totalSupply: {},
  stakedToken: {},
  communityTax: {},
  commissionRate: {},
};

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    setAccountAddress: (state, action) => {
      const account = action.payload;
      state.accounts = account;
    },
    setBalance: (state, action) => {
      const balance = action.payload;
      state.balance = balance;
    },
    setValidator: (state, action) => {
      const validator = action.payload;
      state.validator = validator;
    },
    setDelegation: (state, action) => {
      const delegation = action.payload;
      state.delegation = delegation;
    },
    setInflation: (state, action) => {
      const inflation = action.payload;
      state.inflation = inflation;
    },
    setDelegator: (state, action) => {
      const delegator = action.payload;
      state.delegator = delegator;
    },
    setStakingRewards: (state, action) => {
      const stakingRewards = action.payload;
      state.stakingRewards = stakingRewards;
    },
    setQuotes: (state, action) => {
      const quotes = action.payload;
      state.quotes = quotes;
    },
    setUnbonding: (state, action) => {
      const unbonding = action.payload;
      state.unbonding = unbonding;
    },
    setTotalSupply: (state, action) => {
      const totalSupply = action.payload;
      state.totalSupply = totalSupply;
    },
    setStakedToken: (state, action) => {
      const stakedToken = action.payload;
      state.stakedToken = stakedToken;
    },
    setCommunityTax: (state, action) => {
      const communityTax = action.payload;
      state.communityTax = communityTax;
    },
    setCommissionRate: (state, action) => {
      const commissionRate = action.payload;
      state.commissionRate = commissionRate;
    },
  },
});

export const {
  setAccountAddress,
  setBalance,
  setValidator,
  setDelegation,
  setInflation,
  setDelegator,
  setStakingRewards,
  setQuotes,
  setUnbonding,
  setTotalSupply,
  setStakedToken,
  setCommunityTax,
  setCommissionRate,
} = todosSlice.actions;

export default todosSlice.reducer;

export function setCurrentAccount(account) {
  return async function setsetAccountAddress(dispatch) {
    dispatch(setAccountAddress(account));
  };
}

export const getValidatorInfo = (address) => (dispatch) => {
  const url = getValidatorURL(address);

  axios
    .get(url)
    .then((response) => {
      const data = response.data.result;
      dispatch(getSelfDelegate(data.operator_address));
      dispatch(setValidator(data));
    })
    .catch((err) => {
      dispatch(setValidator({}));
    });
};

export const getSelfDelegate = (operAddress) => (dispatch) => {
  const accountAddress = operatorAddressToAccount(operAddress);
  const url = getStakingDelegatorDelegation(accountAddress, operAddress);

  axios
    .get(url)
    .then((response) => {
      const data = response.data.result;
      dispatch(setDelegation(data));
    })
    .catch((err) => {
      dispatch(setDelegation({}));
    });
};

/////////////////////////////////////////////////////////////////////////

const getQuotesByIds = async () => {
  let allQuotesByIds = {};
  const url = getQuotes();
  try {
    let dataArray = {};

    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
    allQuotesByIds = dataArray;
  } catch (error) {
    allQuotesByIds = {};
  }
  return [allQuotesByIds];
};

export const fetchQuotes = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getQuotesByIds());
  const [[allQuotesByIds]] = await Promise.all(fetchPromise);
  dispatch(setQuotes(allQuotesByIds));
};

const getUnbonding = async ({ address }) => {
  let allUnbonding = {};
  const url = urlFetchUnbonding(address);
  try {
    let dataArray = {};

    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data;
      })
      .catch((e) => {
        console.log(e);
      });
    allUnbonding = dataArray;
  } catch (error) {
    allUnbonding = {};
  }
  return [allUnbonding];
};

export const fetchUnbonding = (address) => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getUnbonding({ address }));
  const [[allUnbonding]] = await Promise.all(fetchPromise);
  dispatch(setUnbonding(allUnbonding));
};

const getFetchRewards = async ({ address }) => {
  let allRewards = {};
  const url = urlFetchRewards(address);
  try {
    let dataArray = {};

    await axios
      .get(url)
      .then(async (resp) => {
        if (typeof resp.data.result !== "undefined") {
          const reward = resp.data.result.filter((data) => {
            return data.denom === "usix"
          })
          dataArray = reward;
        }
      })
      .catch((e) => {
        allRewards = {};
      });
    allRewards = dataArray;
  } catch (error) {
    allRewards = {};
  }
  return [allRewards];
};

export const fetchRewards = (address) => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getFetchRewards({ address }));
  const [[allRewards]] = await Promise.all(fetchPromise);
  dispatch(setStakingRewards(allRewards));
};

const getFetchDelegator = async ({ addressdelegator, operAddress }) => {
  let allDelegator = {};
  const url = getStakingDelegatorDelegation(addressdelegator, operAddress);
  try {
    let dataArray = {};

    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data.result;
      })
      .catch((e) => {
        dataArray = { error: "error" };
      });
    allDelegator = dataArray;
  } catch (error) {
    allDelegator = { error: "error" };
  }
  return [allDelegator];
};

export const fetchDelegator =
  (addressdelegator, operAddress) => async (dispatch) => {
    const fetchPromise = [];
    fetchPromise.push(getFetchDelegator({ addressdelegator, operAddress }));
    const [[allDelegator]] = await Promise.all(fetchPromise);
    dispatch(setDelegator(allDelegator));
  };

const getInflation = async () => {
  let allInflation = 0;
  const url = getMintingInflation();
  try {
    let dataArray = 0;

    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
    allInflation = dataArray;
  } catch (error) {
    allInflation = 0;
  }
  return [allInflation];
};

export const fetchInflation = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getInflation());
  const [[allInflation]] = await Promise.all(fetchPromise);
  dispatch(setInflation(allInflation));
};

const getFetchBalances = async ({ address }) => {
  let allBalances = 0;
  const url = urlFetchBalance(address);
  try {
    let dataArray = 0;

    await axios
      .get(url)
      .then(async (resp) => {
        const data = resp.data.result;
        dataArray =
          data.length &&
          data.find((val) => val.denom === config.COIN_MINIMAL_DENOM);
      })
      .catch((e) => {
        console.log(e);
      });
    allBalances = dataArray;
  } catch (error) {
    allBalances = 0;
  }
  return [allBalances];
};

export const fetchBalances = (address) => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getFetchBalances({ address }));
  const [[allBalances]] = await Promise.all(fetchPromise);
  dispatch(setBalance(Number(allBalances.amount) || 0));
};

const getFetchValidatorInfo = async ({ address }) => {
  let allValidatorInfo = {};
  const url = getValidatorURL(address);
  try {
    let dataArray = {};

    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data.result;
      })
      .catch((e) => {
        console.log(e);
      });
    allValidatorInfo = dataArray;
  } catch (error) {
    allValidatorInfo = {};
  }
  return [allValidatorInfo];
};

export const fetchValidatorInfo = (address) => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getFetchValidatorInfo({ address }));
  const [[allValidatorInfo]] = await Promise.all(fetchPromise);
  dispatch(setValidator(allValidatorInfo));
};

// TOTAL SUPPLY
const getTotalSupply = async () => {
  let allTotalSupply = {};
  const url = urlFetchTotalSupply();

  try {
    let dataArray = {};
    await axios
      .get(url)
      .then(async (resp) => {
        if (typeof resp.data.supply !== "undefined") {
          const supply = resp.data.supply.filter((data) => {
            return data.denom === "usix"
          })
          dataArray = formatTokenAmount(supply[0].amount);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    allTotalSupply = dataArray;
  } catch (error) {
    allTotalSupply = {};
  }
  return [allTotalSupply];
};

export const fetchTotalSupply = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getTotalSupply());
  const [[allTotalSupply]] = await Promise.all(fetchPromise);
  dispatch(setTotalSupply(allTotalSupply));
};

// STAKED TOKEN
const getStakedToken = async () => {
  let allStakedToken = {};
  const url = urlFetchStakedToken();
  try {
    let dataArray = {};
    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = formatTokenAmount(resp.data.pool.bonded_tokens);
      })
      .catch((e) => {
        console.log(e);
      });
    allStakedToken = dataArray;
  } catch (error) {
    allStakedToken = {};
  }
  return [allStakedToken];
};

export const fetchStakedToken = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getStakedToken());
  const [[allStakedToken]] = await Promise.all(fetchPromise);
  dispatch(setStakedToken(allStakedToken));
};

// COMMUNITY TAX
const getCommunityTax = async () => {
  let allCommunityTax = {};
  const url = urlFetchCommunityTax();
  try {
    let dataArray = {};
    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data.params.community_tax;
      })
      .catch((e) => {
        console.log(e);
      });
    allCommunityTax = dataArray;
  } catch (error) {
    allCommunityTax = {};
  }
  return [allCommunityTax];
};

export const fetchCommunityTax = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getCommunityTax());
  const [[allCommunityTax]] = await Promise.all(fetchPromise);
  dispatch(setCommunityTax(allCommunityTax));
};

// COMMISSION RATE
const getCommissionRate = async () => {
  let allCommissionRate = {};
  const url = urlFetchCommissionRate();
  try {
    let dataArray = {};
    await axios
      .get(url)
      .then(async (resp) => {
        dataArray = resp.data.validator.commission.commission_rates.rate;
      })
      .catch((e) => {
        console.log(e);
      });
    allCommissionRate = dataArray;
  } catch (error) {
    allCommissionRate = {};
  }
  return [allCommissionRate];
};

export const fetchCommissionRate = () => async (dispatch) => {
  const fetchPromise = [];
  fetchPromise.push(getCommissionRate());
  const [[allCommissionRate]] = await Promise.all(fetchPromise);
  dispatch(setCommissionRate(allCommissionRate));
};
