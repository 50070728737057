export const menu = [
  { name: "HOME", url: "/" },
  {
    name: "GET STARTED",
    url: "https://sixnetwork.gitbook.io/six-protocol/",
    newTab: true
  },
  { name: "BECOME A NODE", url: "/become-a-node" },
  { name: "LEARN MORE", url: "/learn-more" },
  {
    name: "CONTACT US",
    url: "https://zeeg.me/sixnetwork",
    newTab: true,
  },

  // { name: "NFT GEN2", url: "/nftgen2" },
  // { name: "SIX VALIDATOR NODES", url: "/six-validator-nodes" },
  // { name: "SIX TOKEN", url: "/six-token" },
  // { name: "BECOME A NODE", url: "/partnership-program" },
];
