import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import theme from "./theme";
import store from "./state";

const Providers = ({ children }) => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
};

export default Providers;
