import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Outlet } from "react-router-dom";
import CustomHelmet from "./components/CustomHelmet";
import Footer from "./components/Footer";
import Header from "./components/Header";
import useScrollToTop from "./hooks/useScrollToTop";

ReactGA.initialize("UA-228567463-1");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useScrollToTop();

  return (
    <>
      <CustomHelmet />
      <Header className="transparent" />
      <Box className="d-flex flex-column" sx={{ minHeight: "100vh" }}>
        <Container
          maxWidth="initial"
          className="px-0 flex-grow"
          sx={{ overflowX: "hidden" }}
        >
          <Outlet />
        </Container>
        <Footer className="flex-shrink" />
      </Box>
    </>
  );
}

export default App;
