import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { StdFee } from "@cosmjs/stargate";

interface Fees {
  upload: StdFee;
  init: StdFee;
  exec: StdFee;
}

interface TokensResponse {
  /// Contains all token_ids in lexicographical ordering
  /// If there are more than `limit`, use `start_from` in future queries
  /// to achieve pagination.
  tokens: string[];
}

interface NftInfoResponse {
  /// Universal resource identifier for this NFT
  /// Should point to a JSON file that conforms to the ERC721
  /// Metadata JSON Schema
  token_uri: string;
  /// You can add any custom metadata here when you extend cw721-base
  extension: any;
}

interface OwnerOfResponse {
  /// Owner of the token
  owner: string;
  /// If set this address is approved to transfer/send the token as well
  approvals: any;
}

interface ConfigResponse {
  grace_period: number;
  registry_address: string;
  owner: string;
  base_node: number[];
  base_name: string;
}

interface IsIndividualResponse {
  individual: boolean;
}

interface RegistrarInstance {
  readonly contractAddress: string;

  //Query functions
  //   rentPrice(name: string, duration: number): Promise<RentPriceResponse>;
  allTokens: (start_after?: number, limit?: number) => Promise<TokensResponse>;
  nftInfo: (token_id: string) => Promise<any>;

  ownerOf: (
    token_id: string,
    include_expired: boolean
  ) => Promise<OwnerOfResponse>;
  tokens: (
    owner: string,
    startAfter?: string,
    limit?: number
  ) => Promise<TokensResponse>;

  getConfig: () => Promise<ConfigResponse>;
  isIndividual: (token_id: string) => Promise<IsIndividualResponse>;

  //Execute functions
  addController: (senderAddress: string, address: string) => Promise<any>;
}

interface RegistrarContract {
  use: (contractAddress: string) => RegistrarInstance;
}

export const IRegistrar = (
  client: SigningCosmWasmClient,
  fees: Fees
): RegistrarContract => {
  const use = (contractAddress: string): RegistrarInstance => {
    const allTokens = async (
      start_after?: number,
      limit?: number
    ): Promise<TokensResponse> => {
      return await client.queryContractSmart(contractAddress, {
        all_tokens: { start_after, limit },
      });
    };

    const nftInfo = async (token_id: string): Promise<NftInfoResponse> => {
      return await client.queryContractSmart(contractAddress, {
        nft_info: { token_id },
      });
    };
    const ownerOf = async (
      token_id: string,
      include_expired: boolean
    ): Promise<OwnerOfResponse> => {
      return await client.queryContractSmart(contractAddress, {
        owner_of: { token_id, include_expired },
      });
    };

    const getConfig = async (): Promise<ConfigResponse> => {
      return await client.queryContractSmart(contractAddress, {
        get_config: {},
      });
    };

    const isIndividual = async (id: string): Promise<IsIndividualResponse> => {
      return await client.queryContractSmart(contractAddress, {
        is_individual: { id },
      });
    };

    // list all token_ids that belong to a given owner
    const tokens = async (
      owner: string,
      start_after?: string,
      limit?: number
    ): Promise<TokensResponse> => {
      return client.queryContractSmart(contractAddress, {
        tokens: { owner, start_after, limit },
      });
    };

    const addController = async (
      senderAddress: string,
      address: string
    ): Promise<any> => {
      const res = await client.execute(
        senderAddress,
        contractAddress,
        {
          add_controller: { address },
        },
        fees.exec
      );
      return res;
    };

    return {
      contractAddress,
      isIndividual,
      allTokens,
      nftInfo,
      tokens,
      ownerOf,
      addController,
      getConfig,
    };
  };
  return { use };
};
