import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { NavLink } from "react-router-dom";
import powerBy from "../images/NewUI/powerby_six.png";
import sixProtocol from "../images/NewUI/six_protocol.png";
import { menu } from "../menu";
import BackToTop from "./BackToTop";

const FooterStyle = styled("footer")`
  .MuiButton-root {
    height: 28px;
  }
`;

const Footer = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <FooterStyle>
        <Container maxWidth={xl ? "xl" : "lg"}>
          <Box className="d-flex align-center" position="relative" pt={4}>
            <Link to="/">
              <img
                src={sixProtocol}
                alt="SIX Protocol"
                className="d-block"
                width={100}
              />
            </Link>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ background: "#8B8B8B", ml: 10, mr: 1, width: "2px" }}
            />
            <div>
              {menu.map((m) => (
                <Button
                  key={m.name}
                  component={m.newTab ? "a" : NavLink}
                  to={!m.newTab ? m.url : undefined}
                  href={m.newTab ? m.url : undefined}
                  target={m.newTab ? "_blank" : undefined}
                  color="inherit"
                  className="d-flex justify-start"
                  style={
                    !m.newTab
                      ? ({ isActive }) => {
                          return {
                            color: isActive ? "#269adc" : "",
                            fontWeight: isActive ? "700" : "normal"
                          };
                        }
                      : { fontWeight: "normal" }
                  }
                >
                  {m.name}
                </Button>
              ))}
            </div>
          </Box>

          <Divider
            sx={{
              height: "2px",
              background:
                "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.55) 50%, rgba(255, 255, 255, 0) 100%);",
              mt: 4,
              mb: 3
            }}
          />

          <Box
            className={`d-flex align-center  flex-wrap ${
              sm ? "justify-space-between" : "justify-center"
            }`}
            pb={4}
          >
            <Typography
              variant="caption"
              mb={sm ? 0 : 1.5}
              className="d-flex align-baseline"
              fontSize="12px"
            >
              <Button
                href="/privacy-policy"
                className="pa-0 pr-2"
                color="inherit"
                target="_blank"
                sx={{ fontWeight: "600", fontSize: "inherit" }}
              >
                Privacy Policy
              </Button>
              | © 2022 SIX Network PTE. LTD.
            </Typography>

            <img
              src={powerBy}
              alt=""
              height="24px"
              style={{ marginTop: "-10px" }}
            />
          </Box>
        </Container>
      </FooterStyle>

      <BackToTop />
    </>
  );
};

export default Footer;
