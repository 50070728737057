import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logoBlack from "../images/Footer/SIX-Protocol-NEW-3-18.png";
import logoWhite from "../images/Logo-Header-Drop-shadow.png";
import { menu } from "../menu";

const Header = (props) => {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const [anchorEl, setAnchorEl] = useState();
  const { window } = props;
  const location = useLocation();
  const isDark =
    location.pathname === "/" || location.pathname === "/become-a-node";

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
    target: window ? window() : undefined
  });

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl();
  };

  return (
    <AppBar
      position={isDark ? "sticky" : "fixed"}
      elevation={trigger ? 3 : 0}
      sx={{
        transition: "0.3s",
        background: trigger ? (isDark ? "black" : "white") : "transparent",
        py: trigger ? 0 : 2,

        ".MuiButton-text:not(.active)": {
          color: !isDark ? (theme) => theme.palette.text.primary : "white",
        },
      }}
    >
      <Container maxWidth={xl ? "xl" : "lg"}>
        <Toolbar className="px-0">
          <Link to="/">
            <img
              src={!isDark ? logoBlack : logoWhite}
              alt="SIX Protocol"
              className="d-block"
              height={trigger ? "52px" : "64px"}
              style={{ transition: "0.3s" }}
            />
          </Link>

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}
          >
            {/* Mobile */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                onClick={handleOpenMenu}
                color={isDark ? "inherit" : undefined}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                sx={{
                  display: { xs: "block", md: "none" }
                }}
              >
                {menu.map((m) => (
                  <MenuItem
                    key={`mobile-${m.name}`}
                    component={m.newTab ? "a" : NavLink}
                    to={!m.newTab ? m.url : undefined}
                    href={m.newTab ? m.url : undefined}
                    target={m.newTab ? "_blank" : undefined}
                    onClick={handleCloseMenu}
                    style={
                      !m.newTab
                        ? ({ isActive }) => {
                            return {
                              color: isActive ? "#269adc" : ""
                            };
                          }
                        : undefined
                    }
                  >
                    <Typography align="center" fontWeight="bold">
                      {m.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Desktop */}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {menu.map((m) => (
                <Button
                  key={m.name}
                  component={m.newTab ? "a" : NavLink}
                  to={!m.newTab ? m.url : undefined}
                  href={m.newTab ? m.url : undefined}
                  target={m.newTab ? "_blank" : undefined}
                  color="inherit"
                  className="px-4"
                  style={
                    !m.newTab
                      ? ({ isActive }) => {
                          return {
                            color: isActive ? "#269adc" : ""
                          };
                        }
                      : undefined
                  }
                >
                  {m.name}
                </Button>
              ))}
            </Box>

            {/* <GradientButton
              variant="contained"
              size="small"
              className="ml-4"
              sx={{
                transition: '0.1s',
                background: trigger || !isDark ? '' : 'white',
                color: trigger || !isDark ? '' : 'initial !important',
              }}
            >
              BUILD NOW
            </GradientButton> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
