import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "./wallet";
import nameServiceReducer from "./nameService";
import transactionsReducer from "./transactions";
const store = configureStore({
  reducer: {
    todos: walletReducer,
    nameService: nameServiceReducer,
    transactions: transactionsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
