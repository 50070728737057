import { config } from "../config";

export const REST_URL = config.REST_URL;
export const RPC_URL = config.RPC_URL;
export const COINGECKO_ID = config.COINGECKO_ID;

export const urlFetchBalance = (address) =>
  `${REST_URL}/bank/balances/${address}`;
export const getValidatorURL = (address) =>
  `${REST_URL}/staking/validators/${address}`;
export const getStakingDelegatorDelegation = (delegatorAddr, validatorAddr) =>
  `${REST_URL}/staking/delegators/${delegatorAddr}/delegations/${validatorAddr}`;
export const getMintingInflation = () => `${REST_URL}/minting/inflation`;
export const urlFetchRewards = (address) =>
  `${REST_URL}/distribution/delegators/${address}/rewards/${process.env.REACT_APP_VALIDATOR}`;
export const getName = () =>
  `${process.env.REACT_APP_API_ENDPOINT}/api/getName`;
export const getDuplicateName = () =>
  `${process.env.REACT_APP_API_ENDPOINT}/api/checkDuplicateName`;
export const urlBookingName = () =>
  `${process.env.REACT_APP_API_ENDPOINT}/api/bookName`;
export const urlconfirmName = () =>
  `${process.env.REACT_APP_API_ENDPOINT}/api/confirmName`;
export const urlRegister = () =>
  `${process.env.REACT_APP_WASM_API_ENDPOINT}/api/sns/name/register`;
export const getTransactions = (sender, page) =>
  `${REST_URL}/txs?transfer.recipient=${sender}&page=${page}&limit=5`;
export const getTransactionsDelegate = (sender, page) =>
  `${REST_URL}/txs?message.action=/cosmos.staking.v1beta1.MsgDelegate&message.sender=${sender}&page=${page}&limit=100`;
export const getTransactionsUndelegate = (sender, page) =>
  `${REST_URL}/txs?message.action=/cosmos.staking.v1beta1.MsgUndelegate&transfer.recipient=${sender}&page=${page}&limit=100`;
export const getTransactionsClaim = (sender, page) =>
  `${REST_URL}/txs?message.action=/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward&transfer.recipient=${sender}&page=${page}&limit=100`;
export const getQuotes = () =>
  `https://api.coingecko.com/api/v3/simple/price?ids=${COINGECKO_ID}&vs_currencies=usd&include_24hr_change=true`;
export const urlFetchUnbonding = (address) =>
  `${REST_URL}/cosmos/staking/v1beta1/delegators/${address}/unbonding_delegations`;
export const getUrlTransactions = (sender, page) =>
  `${RPC_URL}/tx_search?order_by="desc"&page="${page}"&query="tx.height>=1%20and%20message.action=%27/cosmos.staking.v1beta1.MsgDelegate%27%20and%20message.sender=%27${sender}%27"&per_page="5"`;
export const urlFetchTotalSupply = () =>
  `${REST_URL}/cosmos/bank/v1beta1/supply`;
export const urlFetchStakedToken = () =>
  `${REST_URL}/cosmos/staking/v1beta1/pool`;
export const urlFetchCommunityTax = () =>
  `${REST_URL}/cosmos/distribution/v1beta1/params`;
export const urlFetchCommissionRate = () =>
  `${REST_URL}/cosmos/staking/v1beta1/validators/${process.env.REACT_APP_VALIDATOR}`;
