import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingPage = () => {
  return (
    <Box
      className="d-flex align-center justify-center"
      sx={{ minHeight: "100vh", background: "white" }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingPage;
