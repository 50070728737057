import { Bech32, fromBase64, fromHex, toHex } from "@cosmjs/encoding";
import { REST_URL, RPC_URL } from "./constants/url";
import { SigningStargateClient } from "@cosmjs/stargate";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { sha256 } from "@cosmjs/crypto";
import { config } from "./config";
import RIPEMD160 from "ripemd160";
import dayjs from "dayjs";
import { makeSignDoc } from "@cosmjs/proto-signing";
import { IRegistrar } from "./contracts/IRegistrar";

const chainId = config.CHAIN_ID;
const chainName = config.CHAIN_NAME;
const coinDenom = config.COIN_DENOM;
const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
const coinDecimals = config.COIN_DECIMALS;
const prefix = config.PREFIX;
const COIN_DECI_VALUE = 1000000;

const chainConfig = {
  chainId: chainId,
  chainName,
  rpc: RPC_URL,
  rest: REST_URL,
  stakeCurrency: {
    coinDenom,
    coinMinimalDenom,
    coinDecimals,
    coinGeckoId: config.COINGECKO_ID
  },
  bip44: {
    coinType: 118
  },
  bech32Config: {
    bech32PrefixAccAddr: `${prefix}`,
    bech32PrefixAccPub: `${prefix}pub`,
    bech32PrefixValAddr: `${prefix}valoper`,
    bech32PrefixValPub: `${prefix}valoperpub`,
    bech32PrefixConsAddr: `${prefix}valcons`,
    bech32PrefixConsPub: `${prefix}valconspub`
  },
  currencies: [
    {
      coinDenom,
      coinMinimalDenom,
      coinDecimals,
      coinGeckoId: config.COINGECKO_ID
    }
  ],
  feeCurrencies: [
    {
      coinDenom,
      coinMinimalDenom,
      coinDecimals,
      coinGeckoId: config.COINGECKO_ID
    }
  ],
  coinType: config.COIN_TYPE,
  gasPriceStep: {
    low: config.GAS_PRICE_STEP_LOW,
    average: config.GAS_PRICE_STEP_AVERAGE,
    high: config.GAS_PRICE_STEP_HIGH
  },
  features: config.FEATURES,
  walletUrlForStaking: config.STAKING_URL
};

export const initializeChain = (cb) => {
  (async () => {
    if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
      const error = "Please install keplr extension";
      cb(error);
    } else {
      if (window.keplr.experimentalSuggestChain) {
        try {
          await window.keplr.experimentalSuggestChain(chainConfig);
        } catch (error) {
          const chainError = "Failed to suggest the chain";
          cb(chainError);
        }
      } else {
        const versionError = "Please use the recent version of keplr extension";
        cb(versionError);
      }
    }

    if (window.keplr) {
      await window.keplr.enable(chainId);

      const offlineSigner = window.getOfflineSignerOnlyAmino(chainId);
      const accounts = await offlineSigner.getAccounts();
      cb(null, accounts);
    } else {
      return null;
    }
  })();
};

export const signName = (address, cb) => {
  (async () => {
    (await window.keplr) && window.keplr.enable(chainId);
    const offlineSigner =
      window.getOfflineSignerOnlyAmino &&
      window.getOfflineSignerOnlyAmino(chainId);
    const client = await SigningStargateClient.connectWithSigner(
      RPC_URL,
      offlineSigner
    );

    const accountOnChain = await client.getAccount(address);
    const accountNumber = accountOnChain.accountNumber;

    const signDoc = makeSignDoc(
      new Uint8Array([]),
      new Uint8Array([]),
      chainId,
      Number(accountNumber)
    );

    try {
      const sign = await window.keplr.signDirect(chainId, address, signDoc);
      cb(null, sign);
    } catch (error) {
      cb(error, null);
    }
  })();
};

export const getNameContracts = (address, cb) => {
  (async () => {
    let allNameIndex = {};
    try {
      (await window.keplr) && window.keplr.enable(chainId);
      const offlineSigner =
        window.getOfflineSignerOnlyAmino &&
        window.getOfflineSignerOnlyAmino(chainId);
      const client = await SigningCosmWasmClient.connectWithSigner(
        RPC_URL,
        offlineSigner
      );
      const registrar = IRegistrar(client, config.FEES);
      const SNS_REGISTRAR = registrar.use(
        config.CONTRACT_DATA.registrar.contract_addr
      );
      const token = await SNS_REGISTRAR.tokens(address);
      const { tokens } = token;
      if (tokens[0] !== undefined) {
        const nftInfo = await SNS_REGISTRAR.nftInfo(tokens[0]);
        const { token_uri, extension } = nftInfo;
        const { name } = extension;
        allNameIndex = { name: name, token_uri: `${token_uri}` };
      } else {
        allNameIndex = {};
      }
      cb(null, allNameIndex);
    } catch (error) {
      allNameIndex = {};
      cb(error, allNameIndex);
    }
  })();
};

export const signTxAndBroadcast = (tx, address, cb) => {
  (async () => {
    (await window.keplr) && window.keplr.enable(chainId);
    const offlineSigner =
      window.getOfflineSignerOnlyAmino &&
      window.getOfflineSignerOnlyAmino(chainId);
    const client = await SigningStargateClient.connectWithSigner(
      RPC_URL,
      offlineSigner
    );
    client
      .signAndBroadcast(address, tx.msgs ? tx.msgs : [tx.msg], tx.fee, tx.memo)
      .then((result) => {
        if (result && result.code !== undefined && result.code !== 0) {
          cb(result.log || result.rawLog);
        } else {
          cb(null, result);
        }
      })
      .catch((error) => {
        cb(error && error.message);
      });
  })();
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatTokenDenom(tokenDenom) {
  if (tokenDenom && tokenDenom.code === undefined) {
    let denom = tokenDenom.denom_trace
      ? tokenDenom.denom_trace.base_denom
      : tokenDenom;
    const config = Object.values(chainConfig());

    config.forEach((x) => {
      if (x.assets) {
        const asset = x.assets.find((a) => a.base === denom);
        if (asset) denom = asset.symbol;
      }
    });
    return denom.startsWith("ibc")
      ? `IBC...${denom.substring(denom.length - 3)}`
      : denom.toUpperCase();
  }
  return "";
}

export function operatorAddressToAccount(operAddress) {
  const { prefix, data } = Bech32.decode(operAddress);
  if (prefix === "iva") {
    // handle special cases
    return Bech32.encode("iaa", data);
  }
  if (prefix === "crocncl") {
    // handle special cases
    return Bech32.encode("cro", data);
  }
  return Bech32.encode(prefix.replace("valoper", ""), data);
}

export function consensusPubkeyToHexAddress(consensusPubkey) {
  let raw = null;
  if (typeof consensusPubkey === "object") {
    if (consensusPubkey.type === "tendermint/PubKeySecp256k1") {
      raw = new RIPEMD160()
        .update(Buffer.from(sha256(fromBase64(consensusPubkey.value))))
        .digest("hex")
        .toUpperCase();
      return raw;
    }
    raw = sha256(fromBase64(consensusPubkey.value));
  } else {
    raw = sha256(
      fromHex(
        toHex(Bech32.decode(consensusPubkey).data)
          .toUpperCase()
          .replace("1624DE6420", "")
      )
    );
  }
  const address = toHex(raw).slice(0, 40).toUpperCase();
  return address;
}

export function percent(num) {
  return parseFloat((num * 100).toFixed(2));
}

export function abbrMessage(msg) {
  if (Array.isArray(msg)) {
    const sum = msg
      .map((x) => abbrMessage(x))
      .reduce((s, c) => {
        const sh = s;
        if (sh[c]) {
          sh[c] += 1;
        } else {
          sh[c] = 1;
        }
        return sh;
      }, {});
    const output = [];
    Object.keys(sum).forEach((k) => {
      output.push(sum[k] > 1 ? `${k}×${sum[k]}` : k);
    });
    return output.join(", ");
  }
  if (msg.typeUrl) {
    return msg.typeUrl
      .substring(msg.typeUrl.lastIndexOf(".") + 1)
      .replace("Msg", "");
  }
  return msg.type.substring(msg.type.lastIndexOf("/") + 1).replace("Msg", "");
}

export function toDay(time, format = "long") {
  if (format === "long") {
    return dayjs(time).format("YYYY-MM-DD HH:mm");
  }
  if (format === "date") {
    return dayjs(time).format("YYYY-MM-DD");
  }
  if (format === "time") {
    return dayjs(time).format("HH:mm:ss");
  }
  if (format === "from") {
    return dayjs(time).fromNow();
  }
  if (format === "to") {
    return dayjs(time).toNow();
  }
  return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTimeRemaining(endtime, startime) {
  const total = Date.parse(endtime) - Date.parse(startime);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  let text = "";

  if (days === -1) {
    text = "Claimed";
  } else {
    text = `${days !== 0 ? days + " Days" : ""} ${
      hours !== 0 ? hours + " Hrs" : ""
    } ${minutes !== 0 ? minutes + " Min" : ""} ${
      seconds !== 0 ? seconds + " Sec" : ""
    }`;
  }

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
    text
  };
}

export function formatTokenAmount(tokenAmount) {
  return Number(Number(tokenAmount)) / 10 ** 6;
}

export function formatReward(amount) {
  return parseFloat(Number(Number(amount)) / 10 ** 6).toFixed(6);
}

export function isNUndefined(value) {
  return value !== undefined;
}

export function isUndefined(value) {
  return value === undefined;
}

export function isEmpty(value) {
  return value === "";
}

export function isNEmpty(value) {
  return value !== "";
}

export function getValueObject(
  type,
  address,
  validatorAddress,
  allNodeSelected
) {
  switch (type) {
    case "Stake":
    case "Delegate":
    case "Undelegate":
      return {
        delegatorAddress: address,
        validatorAddress: validatorAddress,
        amount: {
          amount: String(allNodeSelected * COIN_DECI_VALUE),
          denom: config.COIN_MINIMAL_DENOM
        }
      };
    case "Withdraw":
      return {
        delegatorAddress: address,
        validatorAddress: validatorAddress
      };
    default:
      return {};
  }
}

export function getFeeObject(amount, gas) {
  return {
    amount: [
      {
        amount: String(amount * config.GAS_PRICE_STEP_AVERAGE),
        denom: coinMinimalDenom
      }
    ],
    gas: String(gas)
  };
}
