import React, { useEffect } from "react";

const defaultState = {
  slow: 0,
  fast: 0
};

const FAST_INTERVAL = 10000;
const SLOW_INTERVAL = 60000;

export const RefreshContext = React.createContext(defaultState);

export const RefreshProvider = ({ children }) => {
  const [slow, setSlow] = React.useState(defaultState.slow);
  const [fast, setFast] = React.useState(defaultState.fast);

  useEffect(() => {
    const interval = setInterval(async () => {
      setFast((prev) => prev + 1);
    }, FAST_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      setSlow((prev) => prev + 1);
    }, SLOW_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <RefreshContext.Provider value={{ slow, fast }}>
      {children}
    </RefreshContext.Provider>
  );
};
